<template>
  <div class="wap-product-detail user">
    <headerBar :title="$t('ding-dan-xiang-qing')" @back="back"></headerBar>

    <div class="detail-box">
      <div class="flex-center-between line">
        <div class="label">{{ $t('ding-dan-hao-0') }}</div>
        <div class="value info">
          <div class="flex-center">
            {{ detailData.OrderNo }}
            <i class="el-icon-copy-document copy-icon" @click="copyData"></i>
          </div>
          <div class="gray">{{ detailData.CreatedAt }}</div>
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shou-jian-di-zhi') }}</div>
        <div class="value info">
          <div>
            {{ detailData.ShippingAddress.Country }}
            {{ detailData.ShippingAddress.Province }}
            {{ detailData.ShippingAddress.City }}
          </div>
          <div>{{ detailData.ShippingAddress.Address }}</div>
          <div>{{ detailData.ShippingAddress.ZipCode }}</div>
          <div>{{ detailData.ShippingAddress.FullName }}</div>
          <div>{{ detailData.ShippingAddress.Phone }}</div>
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('ding-dan-zhuang-tai') }}</div>
        <div class="value flex-center">
          {{ getStatusName(detailData.Status) }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shang-pin-shu-liang') }}</div>
        <div class="value flex-center">
          {{ detailData.OrderProduct.length }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('ding-dan-zong-e') }}</div>
        <div class="value blue flex-center">${{ detailData.TotalAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-zong-e') }}</div>
        <div class="value blue flex-center">${{ detailData.PickupAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('li-run') }}</div>
        <div class="value blue flex-center">${{ detailData.Income }}</div>
      </div>
    </div>

    <div
      class="detail-box"
      v-for="(good, i) in detailData.OrderProduct"
      :key="i"
    >
      <div class="top-info flex">
        <div class="img">
          <img :src="good.BusinessProduct.MainImage" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="name">
            {{
              good.BusinessProduct.ProductTranslation
                ? good.BusinessProduct.ProductTranslation[0].Name
                : ''
            }}
          </div>
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shu-liang') }}</div>
        <div class="value blue">{{ good.Number }}</div>
      </div>

      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-shou-jia') }}</div>
        <div class="value blue">${{ good.Price }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-jia') }}</div>
        <div class="value blue">${{ good.PickupAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('li-run-0') }}</div>
        <div class="value blue">${{ good.Income }}</div>
      </div>
    </div>

    <div class="detail-box">
      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-ji') }}</div>
        <div class="value name">${{ detailData.TotalAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shui') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('yun-fei') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('he-ji') }}</div>
        <div class="value blue">${{ detailData.TotalAmount }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import { shopProductList } from '@/api/shop'
import Clipboard from 'clipboard'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      messageUrl: require('@/assets/imgs/icon-message.png'),
      dataId: '',
      detailData: {
        ShippingAddress: {},
        OrderProduct: []
      },
      logistics: {},
      logisticsList: []
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      let data = sessionStorage.getItem('orderDetailData')
      if (data) {
        this.detailData = JSON.parse(data)
        console.log(111, this.detailData)
      }
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    copyData(data) {
      const clipboard = new Clipboard('.copy-icon', {
        text: () => this.detailData.OrderNo
      })
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    }
  }
}
</script>